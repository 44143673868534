import * as Sentry from "@sentry/vue";
import axios, { AxiosError } from "axios";
import { ApiError } from "openapi-typescript-fetch";
import { defineStore } from "pinia";
import { match, P } from "ts-pattern";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Urls from "@/autogen/urls";
import { entries } from "@/functional_utils";
import api from "@/lib/api/internal-base";
import { Feature, requiredPlan } from "@/screens/Billing/lib";
import {
  LocalStorageKey,
  safeLocalStorageGetItem,
} from "@/store/local_storage";
import { Account, Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";
import { notify } from "@/utils";
declare let account: Account | null;

declare const DEMO_USERNAME: string;

export const PERMISSION_DENIED_ERROR_MESSAGE =
  "You don't have permission to access this resource.";

export const constructErrorMessage = (err: AxiosError<any>): string => {
  // If it's a cancellation, nack.
  if (axios.isCancel(err)) {
    return "ignore";
  }
  if (!err.response) {
    return "An error occurred while communicating with the server.";
  }
  const { data, status } = err.response;
  if (status === 403) {
    return PERMISSION_DENIED_ERROR_MESSAGE;
  }
  if (status === 400) {
    if (data.url) {
      return data.url;
    } else if (Object.keys(data).length > 0) {
      return match(data)
        .with({ detail: P.string }, () => data.detail)
        .with(P.array(P.string), () => data.join(", "))
        .with(
          {
            tint_color: P.array(P.string),
          },
          () => {
            const [key, value] = entries(data)[0];
            if (value[0].includes("This field may not be blank.")) {
              return `\`${key}\` may not be blank.`;
            }
          }
        )
        .with(
          {
            username: ["newsletter with this username already exists."],
          },
          () => {
            return "A newsletter with this username already exists.";
          }
        )
        .otherwise(
          () =>
            "Buttondown was unable to handle your inputs: " +
            JSON.stringify(data)
        );
    } else {
      return "Please double check your inputs to make sure everything looks valid.";
    }
  }
  return "An unexpected error occured.";
};

const handleError = (
  err: AxiosError<any>,
  setPaidFeatureDialog: (arg0: boolean) => void
) => {
  const message = constructErrorMessage(err);
  if (message === "ignore") {
    return;
  } else if (message.includes("allow for custom domains.")) {
    setPaidFeatureDialog(true);
  } else {
    notify(message, "error");
  }
  throw err;
};

const fetchActiveNewsletter = (account: Account): Newsletter | null => {
  if (!account.permissions) {
    return null;
  }
  const activeNewsletterId = safeLocalStorageGetItem(
    LocalStorageKey.ACTIVE_NEWSLETTER_ID
  );
  if (activeNewsletterId) {
    const matchingNewsletter = account.permissions.find(
      (permission) => permission.newsletter.id === activeNewsletterId
    );
    if (matchingNewsletter) {
      return matchingNewsletter.newsletter;
    }
  }
  return account.permissions ? account.permissions[0].newsletter : null;
};

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const useStore = defineStore(
  "main",
  () => {
    const router = useRouter();
    const activeAccount = ref<Account | null>(account);
    const activeNewsletter = ref<Newsletter | null>(
      account ? fetchActiveNewsletter(account) : null
    );
    const switchNewsletter = (n: Newsletter) => {
      activeNewsletter.value = n;
      localStorage.setItem(LocalStorageKey.ACTIVE_NEWSLETTER_ID, n.id);
      const currentRoute = router.currentRoute.value;
      if (UUID_REGEX.test(currentRoute.fullPath)) {
        router.push({
          name: "/emails",
        });
      }
    };
    const updateAccount = async (account: Account) => {
      try {
        const response = await axios.put(
          Urls["account-detail"](account.id),
          account
        );
        activeAccount.value = response.data;
        notify("Updated settings!", "success");
      } catch (err: any) {
        handleError(err, setPaidFeatureDialog);
      }
    };

    const updatingNewsletterAbortController = ref<AbortController | null>(null);

    const updateNewsletter = async (newsletter: Newsletter) => {
      try {
        updatingNewsletterAbortController.value?.abort(
          "Aborting request because newsletter has changed."
        );

        const source = new AbortController();
        updatingNewsletterAbortController.value = source;

        const response = await api
          .path("/newsletters/{id}")
          .method("put")
          .create()({ ...newsletter }, { signal: source.signal });

        activeNewsletter.value = {
          ...response.data,
          //@ts-ignore
          api_key: activeNewsletter.value?.api_key,
        };

        if (activeAccount.value) {
          activeAccount.value.permissions = activeAccount.value.permissions.map(
            (p: Permission) => {
              if (p.newsletter.id === response.data.id) {
                return {
                  ...p,
                  newsletter: response.data,
                };
              } else {
                return p;
              }
            }
          );
        }
        notify("Updated settings!", "success");
      } catch (err) {
        const typedError = err as ApiError;
        let errorMessage: string | string[];

        try {
          errorMessage = JSON.parse(typedError.data.detail);
        } catch {
          errorMessage =
            typedError.data.detail ||
            "An error occurred while communicating with the server.";
        }

        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((detail) => notify(detail, "error"));
        } else if (typeof errorMessage === "string") {
          notify(errorMessage, "error");
        } else {
          notify(
            "An error occurred while communicating with the server.",
            "error"
          );
        }
      }
    };

    const deleteAccount = async () => {
      await axios.delete(Urls["account-detail"](activeAccount.value?.id || ""));
      notify("Deleted your account.  Have a good day!", "success");
      window.location.href = "/";
    };
    const deleteNewsletter = async (newsletter: Newsletter) => {
      await api.path("/newsletters/{id}").method("delete").create()({
        id: newsletter.id,
      });
      window.location.href = "/settings";
    };

    const showingDialog = ref(false);
    const showingDrawer = ref(false);
    const showingSidebar = ref(true);
    const showingPaidFeatureDialog = ref(false);

    const toggleDialog = (value: boolean) => {
      showingDialog.value = value;
    };
    const toggleSidebar = () => {
      showingSidebar.value = !showingSidebar.value;
    };
    const toggleDrawer = (value: boolean) => {
      showingDrawer.value = value;
    };
    const setPaidFeatureDialog = (value: boolean) => {
      showingPaidFeatureDialog.value = value;
    };

    const currentPermissions = computed(() => {
      return (
        activeAccount.value?.permissions.find(
          (p: Permission) => p.newsletter.id === activeNewsletter.value?.id
        )?.permissions || {}
      );
    });

    const logout = async () => {
      await axios.delete(Urls["authenticate"](), {});
      window.location.href = "/";
    };

    const login = async (payload: {
      username: string;
      password: string;
      code?: string;
    }) => {
      const config = {
        headers: {
          Authorization: `Basic ${btoa(JSON.stringify(payload))}`,
        },
      };
      const response = await axios.post<any>(
        Urls["authenticate"](),
        {},
        config
      );
      const user = response.data;
      const accountResponse = await axios.get(
        Urls["account-detail"](user.account),
        {}
      );
      activeAccount.value = accountResponse.data;
      activeNewsletter.value = fetchActiveNewsletter(accountResponse.data);
      // Set the scope in Sentry.
      Sentry.setUser({
        username: accountResponse.data.username,
        email: accountResponse.data.email_address,
        id: accountResponse.data.id,
      });
    };

    const createAccount = async (payload: {
      username: string;
      password: string;
      email: string;
    }) => {
      await axios.post(Urls["register"](), payload);
      await login(payload);
    };

    watch(
      activeNewsletter,
      () => {
        const tintColor = activeNewsletter.value?.tint_color || "#0069FF";
        document.documentElement.style.setProperty("--tint-color", tintColor);
      },
      {
        immediate: true,
      }
    );

    const calculatePlanRequirement = (feature: Feature) => {
      if (activeAccount.value === null) {
        return null;
      }

      // Janky, but easier than bringing in the entire owning account.
      // This assumes that the two accounts have the same billing type, which seems very safe.
      const accountWithOwningAccountBillingPlan = {
        ...activeAccount.value,
        billing_plan:
          activeNewsletter.value?.owning_account_billing_plan ??
          activeAccount.value.billing_plan,
        features:
          activeNewsletter.value?.owning_account_features ??
          activeAccount.value.features,
      };
      return requiredPlan(accountWithOwningAccountBillingPlan, feature);
    };

    const isDemo = computed(() => DEMO_USERNAME !== "");

    return {
      showingSidebar,
      showingDialog,
      toggleDialog,
      deleteAccount,
      currentPermissions,
      toggleSidebar,
      showingPaidFeatureDialog,
      isDemo,
      setPaidFeatureDialog,
      newsletter: activeNewsletter,
      account: activeAccount,
      switchNewsletter,
      updateAccount,
      deleteNewsletter,
      updateNewsletter,
      logout,
      login,
      createAccount,
      toggleDrawer,
      showingDrawer,
      calculatePlanRequirement,
    };
  },
  {
    // @ts-ignore
    // This is via `pinia-shared-state`, and I don't care enough to make it TS-happy.
    share: {
      // Imagine two tabs open, one in a route with a drawer and one without. Without this flag, the latter route
      // would be offset by the drawer's width (due to the sizing stuff we handle in `App.vue`.)
      omit: ["showingDrawer"],
    },
  }
);
